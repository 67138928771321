import { useEffect, useState } from 'react';
import { pickBy } from 'lodash';
import { QuoteT, SummaryQuoteT, UseBuybackResult } from './types';
import {
  BuybackQuoteUS,
  DeviceCategory,
  DeviceFunctionalT,
  HousingCondition,
  ReebeloBuybackConditionT,
  ScreenCondition,
} from '@/components/buyback/common';
import { DEFAULT_FINAL_QUOTE } from './constants';
import { VendorQuoteT, useApi } from '@/components/buyback/services/api';
import settings from '@/settings';
import { streamlineCondition } from './helpers';

const useBuyback = (): UseBuybackResult => {
  const buybackApi = useApi();

  const [isLoading, setIsLoading] = useState(true);
  const [finalQuote, setFinalQuote] = useState<BuybackQuoteUS | null>(null);
  const [quote, setQuote] = useState<QuoteT>(null);
  const [summaryQuote, setSummaryQuote] = useState<SummaryQuoteT | null>(null);
  const [vendor, setVendor] = useState<VendorQuoteT | null>(null);

  // Set Quote in LocalStorage
  useEffect(() => {
    if (typeof window !== 'undefined' && !isLoading) {
      const strQuote = JSON.stringify(quote);

      localStorage.setItem('quote', strQuote);
    }
  }, [quote, isLoading]);

  // Set SummaryQuote in LocalStorage
  useEffect(() => {
    if (typeof window !== 'undefined' && !isLoading) {
      const strSummary = JSON.stringify(summaryQuote);

      localStorage.setItem('summaryQuote', strSummary);
    }
  }, [summaryQuote, isLoading]);

  // Set Final Quote in LocalStorage
  useEffect(() => {
    if (typeof window !== 'undefined' && !isLoading) {
      const strFinalQuote = JSON.stringify(finalQuote);

      localStorage.setItem('finalQuote', strFinalQuote);
    }
  }, [finalQuote, isLoading]);

  // Retrieve Quote Summary + Accepted Quote
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const strQuote = localStorage.getItem('quote');
      const strSummaryQuote = localStorage.getItem('summaryQuote');
      const strFinalQuote = localStorage.getItem('finalQuote');

      if (strQuote) {
        const parsedQuote = JSON.parse(strQuote);

        setQuote(parsedQuote || null);
      } else {
        setQuote(null);
      }

      if (strSummaryQuote) {
        const parsedSummaryQuote = JSON.parse(strSummaryQuote);

        setSummaryQuote(parsedSummaryQuote || null);
      } else {
        setSummaryQuote(null);
      }

      if (strFinalQuote) {
        const parsedFinalQuote = JSON.parse(strFinalQuote);

        setFinalQuote(parsedFinalQuote || DEFAULT_FINAL_QUOTE);
      } else {
        setFinalQuote(DEFAULT_FINAL_QUOTE);
      }
    }
    setIsLoading(false);
  }, []);

  // Handle Final Quote Updates
  const updateFinalQuote = (updates: Record<string, any>) => {
    if (finalQuote) {
      const updatedFinalQuote = { ...finalQuote, ...updates };

      setFinalQuote(updatedFinalQuote);
    }
  };

  // Update Final Quote depending on Quote
  useEffect(() => {
    if (
      quote &&
      quote.productId !== '' &&
      finalQuote?.finalQuote === 0 &&
      finalQuote?.vendorName === '' &&
      summaryQuote
    ) {
      const payload = {
        // Housing
        'Housing Minor': summaryQuote.housing === 'Minor Scratches',
        'Housing Moderate': summaryQuote.housing === 'Moderate Scratches',
        'Housing Cracked': summaryQuote.housing === 'Cracked or chipped',
        'Housing Flawless': summaryQuote.housing === 'Flawless',

        // Screen
        'Screen Minor': summaryQuote.screen === 'Minor Scratches',
        'Screen Moderate': summaryQuote.screen === 'Moderate Scratches',
        'Screen Cracked': summaryQuote.screen === 'Cracked or chipped',
        'Screen Flawless': summaryQuote.screen === 'Flawless',

        // Unlocked status
        Unlocked: finalQuote.carrier === 'Fully Unlocked',
        'Locked with AT&T': finalQuote.carrier === 'AT&T',
        'Locked with Verizon': finalQuote.carrier === 'Verizon',
        'Locked with other Carrier': ![
          'Fully Unlocked',
          'AT&T',
          'Verizon',
        ].includes(finalQuote.carrier),

        // Other issues
        ...finalQuote.deviceIssues,
      };

      const cleanIssues = pickBy(payload, (isSelected) => isSelected === true);

      buybackApi
        .getProductQuote(quote.productId, cleanIssues, finalQuote.batteryHealth)
        .then((res) => {
          if (res.data.length) {
            const matchingVendor = res.data.sort(
              (a, b) => (b.price ?? 0) - (a.price ?? 0),
            )[0];

            setVendor(matchingVendor);
          }
        });
    }
  }, [quote, summaryQuote, finalQuote, buybackApi]);

  useEffect(() => {
    if (finalQuote) {
      let toUpdate: Partial<BuybackQuoteUS> = {};

      if (quote) {
        toUpdate = {
          ...toUpdate,
          deviceName: quote.model,
          brand: quote.brand ?? '',
          storage: quote.storage ?? '',
          carrier: quote.carrier ?? '',
          watchCaseMaterial: quote.watchCaseMaterial ?? '',
          cpu: quote.cpu ?? '',
          ram: quote.ram ?? '',
          connectivity: quote.connectivity ?? '',
          category: quote.category as DeviceCategory,
          condition: (quote.condition as ReebeloBuybackConditionT) ?? '',
          productId: quote.productId,
          id: quote.productId,
          rid: quote.rid,
          deviceIssues: quote.deviceSpecificIssues ?? {},
          image: quote.image,
        };
      }

      if (summaryQuote) {
        toUpdate = {
          ...toUpdate,
          housing: summaryQuote.housing
            ? (streamlineCondition(
                summaryQuote.housing,
                'housing',
              ) as HousingCondition)
            : '',
          screen: summaryQuote.screen
            ? (streamlineCondition(
                summaryQuote.screen,
                'screen',
              ) as ScreenCondition)
            : '',
          deviceFunctional: summaryQuote.condition as DeviceFunctionalT,
          unlockedStatus: summaryQuote.carrier ?? '',
        };
      }

      if (vendor) {
        toUpdate = {
          ...toUpdate,
          vendorName: vendor.vendor.displayName,
          vendorSlug: vendor.vendor.slug,
          vendorMailInLocation: vendor.vendor.mailInLocation || null,
          vendorCommission: vendor.vendor.commission,
          finalQuote: vendor.price ?? 0,
        };
      }

      updateFinalQuote(toUpdate);
    }
  }, [quote, summaryQuote, vendor]);

  // Reset Final Quote
  const resetQuote = () => {
    setVendor(null);
    setSummaryQuote(null);
    setQuote(null);
    setFinalQuote(DEFAULT_FINAL_QUOTE);
  };

  // Includes Trade In
  const includesTradeIn =
    ['reebelo-dev', 'reebelo-us'].includes(settings.store) &&
    !!quote?.isAccepted &&
    ['Zelle', 'Paypal'].includes(finalQuote?.paymentMethod ?? '');

  return {
    updateFinalQuote,
    finalQuote,
    quote,
    setQuote,
    resetQuote,
    summaryQuote,
    setSummaryQuote,
    isLoading,
    includesTradeIn,
  };
};

export default useBuyback;
